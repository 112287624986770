import last from 'lodash/last';
import React from 'react';
import { getTextBetweenFigcaption } from 'src/utils/urlRegex';
import clsx from 'clsx';

interface EmbedProps {
  url: string;
  innerHTML: string;
  providerNameSlug: 'youtube' | 'vimeo';
  align?: 'wide' | 'center' | 'full';
}

const Embed: React.FunctionComponent<EmbedProps> = (props) => {
  const { url, innerHTML, providerNameSlug, align } = props;
  const description = getTextBetweenFigcaption(innerHTML);
  let iframeUrl = url;

  if (providerNameSlug === 'youtube') {
    const regex =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const regexId = url.match(regex);
    let id = last(url.split('?v='));

    if (regexId) {
      id = regexId[2];
    }

    iframeUrl = `https://www.youtube.com/embed/${id}`;
  }

  if (providerNameSlug === 'vimeo') {
    const regExp = /^.+vimeo.com\/(.*\/)?([^#?]*)/;
    const match = url.match(regExp);

    if (match) {
      iframeUrl = `https://player.vimeo.com/video/${match[2]}`;
    }
  }

  return (
    <div
      className={clsx('mb-24 coreEmbed', {
        alignfull: align === 'full',
        alignwide: align === 'wide',
      })}
    >
      <div className="aspect-w-16 aspect-h-9">
        <iframe
          src={iframeUrl}
          className="h-full w-full"
          allowFullScreen
          loading="lazy"
        />
      </div>
      {!!description && (
        <p
          className="mt-16 text-xs text-neutral-4"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
    </div>
  );
};

export default Embed;
